/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
//css
import './src/styles/scss/styles.scss'; // css


import { siteMetadata } from './gatsby-config';

let loaded = false;

const digitalAnalytics = pathname => {
  window.gas && window.gas('send', 'pageview', pathname);
};

const googleAnalytics = pathname => {
  window.ga && window.ga('send', 'pageview', pathname);
};

const loadScript = (src, onLoad, attrs = {}) => new Promise(resolve => {
  const script = document.createElement('script');
  script.src = src;
  Object.assign(script, attrs);
  script.onload = () => {
    onLoad();
    resolve();
  };
  document.body.appendChild(script);
});

export const onInitialClientRender = () => {
  const { dap, ga , searchgov, gtm } = siteMetadata;
  const { pathname } = window.location;

  const scripts = [];

  if(gtm && gtm.id){
    const gtmElement = document.createElement('script');
    gtmElement.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-P2QHP335');`;
    document.head.prepend(gtmElement);

    const gtmNoscript = document.createElement('noscript');
    const gtmIframe = document.createElement('iframe');
    gtmIframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtm.id}`);
    gtmIframe.setAttribute('height', '0');
    gtmIframe.setAttribute('width', '0');
    gtmIframe.setAttribute('style', 'display:none;visibility:hidden');
    gtmNoscript.appendChild(gtmIframe);
    document.body.prepend(gtmNoscript);
  }

  if (dap && dap.agency) {
    let src = `https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=${dap.agency}`;
    if (dap.subagency) {
      src += `&subagency=${dap.subagency}`;
    }
    const onLoad = () => digitalAnalytics(pathname);
    scripts.push(loadScript(src, onLoad, { id: '_fed_an_ua_tag'}));
  }

  if (ga && ga.ua) {
    // const src = `https://www.googletagmanager.com/gtag/js?id=${ga.ua}`;
    // const onLoad = () => googleAnalytics(pathname);
    // scripts.push(loadScript(src, onLoad));

    /**
     * `forceSSL` was used for analytics.js (the older Google Analytics script).
     * It isn't documented for gtag.js, but the term occurs in the gtag.js code;
     * figure it doesn't hurt to leave it in. -@afeld, 5/29/19
     */
    const gtag = document.createElement('script');
    // gtag.textOld = `
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() { dataLayer.push(arguments); }
    //   gtag('js', new Date());
    //   gtag('config', '${ga.ua}', { 'anonymize_ip': true, 'forceSSL': true });
    // `;
    gtag.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TMDHTLQ');`;
    document.body.appendChild(gtag);

    const gtagNoscript = document.createElement('noscript');
    const gtagIframe = document.createElement('iframe')
    gtagIframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${ga.ua}`)
    gtagIframe.setAttribute('height', '0')
    gtagIframe.setAttribute('width', '0')
    gtagIframe.setAttribute('style', 'display:none;visibility:hidden')
    gtagNoscript.appendChild(gtagIframe)
    document.body.prepend(gtagNoscript)
  }



  if (searchgov && searchgov.affiliate && searchgov.suggestions) {
    const config = document.createElement('script');
    config.text = `
      var usasearch_config = { siteHandle: "${searchgov.affiliate}" };
    `;
    document.body.appendChild(config);
    
    const src="https://search.usa.gov/javascripts/remote.loader.js";
    const onLoad = () => console.log("Typeahead suggestions loaded.");
    scripts.push(loadScript(src, onLoad));
    
  }

  Promise.all(scripts)
    .then(() => { loaded = true });
};

export const onRouteUpdate = ({ location }) => {
  if (loaded) {
    digitalAnalytics(location.pathname);
    googleAnalytics(location.pathname);
  }
};